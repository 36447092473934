<template>
    <div v-if="showSwitch" class="d-flex">
        <v-select
            style="max-width: 150px"
            v-model="selectedCompany"
            hide-details
            dense
            rounded
            filled
            :items="companyItems"
            @change="handleCompanyChange"
            return-object
            item-text="name"
            item-value="company_id">
            <template v-slot:prepend-item>
                <span class="quarolbl ma-2">Sub Licenses</span>
            </template>
        </v-select>
    </div>
</template>

<script>
    import { ref, onMounted, watch } from "@vue/composition-api"
    import axios from "axios"
    const CryptoJS = require("crypto-js")
    // import store from "../store/index"
    export default {
        setup() {
            const companyItems = ref([])
            const selectedCompany = ref(null)
            const showSwitch = ref(false)
            //TODO: set selected Company based on company_id
            const fetchSublicenses = async () => {
                try {
                    let url = process.env.VUE_APP_APIURL + "/user/subcompanies"
                    let authsplits = process.env.VUE_APP_AUTH.split(":")
                    let response_data = await axios.post(
                        url,
                        { mail: localStorage.user },
                        {
                            auth: {
                                username: authsplits[0],
                                password: authsplits[1]
                            }
                        }
                    )
                    console.log(response_data)
                    if (response_data.data.subcompanies.length) showSwitch.value = true
                    companyItems.value = response_data.data.subcompanies

                    // Temporäre Daten für das Beispiel
                    console.log("HI")
                } catch (error) {
                    console.error("Error fetching companies:", error)
                }
            }

            const handleCompanyChange = async () => {
                console.log("company switched")
                //TODO: switch company

                try {
                    console.log(selectedCompany)
                    console.log(selectedCompany.value)

                    let url = process.env.VUE_APP_APIURL + "/user/company/switch/mail"
                    let authsplits = process.env.VUE_APP_AUTH.split(":")
                    let response_data = await axios.post(
                        url,
                        { mail: localStorage.user, company_id: selectedCompany.value.company_id },
                        {
                            auth: {
                                username: authsplits[0],
                                password: authsplits[1]
                            }
                        }
                    )
                    console.log(response_data)
                    delete localStorage.company_id
                    console.log(selectedCompany.value.company_id)
                    let comp_string = selectedCompany.value.company_id.toString()
                    // Verschlüsseln und in localStorage speichern
                    let encryptedCompanyId = CryptoJS.AES.encrypt(comp_string, process.env.VUE_APP_ENCRYPTION_PW).toString()
                    localStorage.setItem("company_id", encryptedCompanyId)
                    console.log(localStorage.company_id)
                    // await store.dispatch("FETCH_STARTUP_DATA", CryptoJS.AES.decrypt(localStorage.user, process.env.VUE_APP_ENCRYPTION_PW).toString(CryptoJS.enc.Utf8))
                    window.location.reload()
                    // Temporäre Daten für das Beispiel
                    console.log("HI")
                } catch (error) {
                    console.error("Error fetching companies:", error)
                }
            }
            const handleCompanyItemsChange = (newItems, oldItems) => {
                selectedCompany.value = newItems.find((x) => x.company_id == CryptoJS.AES.decrypt(localStorage.company_id, process.env.VUE_APP_ENCRYPTION_PW).toString(CryptoJS.enc.Utf8))
            }

            onMounted(() => {
                fetchSublicenses()
            })

            // watch(selectedCompany, handleCompanyChange)
            watch(companyItems, handleCompanyItemsChange)

            return {
                companyItems,
                selectedCompany,
                showSwitch,
                handleCompanyChange
            }
        }
    }
</script>
